import React from "react";
import { Fragment } from "react";
//import { LayoutClient } from "../layouts";

import Login from "../modules/Login/Login";
import Logout from "../modules/Login/Logout";
import Register from "../modules/Login/Register";
/////////////admin/////////////////////////

const Dashboard = React.lazy(() => import("../modules/Dashboard/Dashboard"));
const Menu = React.lazy(() => import("../modules/Menu"));
const Module_Permission = React.lazy(() => import("../modules/Module_Permission"));
const Function_Permission = React.lazy(() => import("../modules/Function_Permission"));
const Role = React.lazy(() => import("../modules/Role"));
const Staff = React.lazy(() => import("../modules/Staff"));
const DiaryPersonal = React.lazy(() => import("../modules/DiaryPersonal"));
const User = React.lazy(() => import("../modules/User"));
const ReportAccount = React.lazy(() => import("../modules/ReportAccount"));
const Product = React.lazy(() => import("../modules/Product"));
const ProductCate = React.lazy(() => import("../modules/ProductCate"));
const Organize = React.lazy(() => import("../modules/Organize"));
const OrganizeInfo = React.lazy(() => import("../modules/OrganizeInfo"));
const CalendarWork = React.lazy(() => import("../modules/CalendarWork"));
/////////////danh muc////
const CateNationality = React.lazy(() => import("../modules/CateNationality"));
const CateReligion = React.lazy(() => import("../modules/CateReligion"));
const CateOrganize = React.lazy(() => import("../modules/CateOrganize"));
const CateEthnic = React.lazy(() => import("../modules/CateEthnic"));
const CatePositionUnion = React.lazy(() => import("../modules/CatePositionUnion"));
const CatePositionParty = React.lazy(() => import("../modules/CatePositionParty"));
const CatePositionTradeUnion = React.lazy(() => import("../modules/CatePositionTradeUnion"));
const CateLanguageCertificate = React.lazy(() => import("../modules/CateLanguageCertificate"));
const CateInformaticsCertificate = React.lazy(() => import("../modules/CateInformaticsCertificate"));
const CateFormsTraining = React.lazy(() => import("../modules/CateFormsTraining"));

//////////////Ho khau & nhan khau/////////
const Rsdt_CateRelationship = React.lazy(() => import("../modules/Rsdt_CateRelationship"));
const Rsdt_Household = React.lazy(() => import("../modules/Rsdt_Household"));
const Rsdt_People = React.lazy(() => import("../modules/Rsdt_People"));
const Rsdt_DiaryStatusHousehold = React.lazy(() => import("../modules/Rsdt_DiaryStatusHousehold"));
const Rsdt_DiaryMeritHousehold = React.lazy(() => import("../modules/Rsdt_DiaryMeritHousehold"));
const Rsdt_DiaryPolicyHousehold = React.lazy(() => import("../modules/Rsdt_DiaryPolicyHousehold"));
const Rsdt_DiaryBusinessHousehold = React.lazy(() => import("../modules/Rsdt_DiaryBusinessHousehold"));
const Rsdt_DiaryRegisterCultureHousehold = React.lazy(() => import("../modules/Rsdt_DiaryRegisterCultureHousehold"));
const Rsdt_DiaryCultureHousehold = React.lazy(() => import("../modules/Rsdt_DiaryCultureHousehold"));
const Rsdt_DiaryPersonal = React.lazy(() => import("../modules/Rsdt_DiaryPersonal"));
const Rsdt_DiaryVaccination = React.lazy(() => import("../modules/Rsdt_DiaryVaccination"));
const Rsdt_DiaryEvils = React.lazy(() => import("../modules/Rsdt_DiaryEvils"));
const Rsdt_DiaryMilitary = React.lazy(() => import("../modules/Rsdt_DiaryMilitary"));
const Rsdt_DiaryUnionMembers = React.lazy(() => import("../modules/Rsdt_DiaryUnionMembers"));
const Rsdt_DiaryUnionWomen = React.lazy(() => import("../modules/Rsdt_DiaryUnionWomen"));
const Rsdt_DiaryEducation = React.lazy(() => import("../modules/Rsdt_DiaryEducation"));
const Rsdt_DiarySubsidize = React.lazy(() => import("../modules/Rsdt_DiarySubsidize"));
const Rsdt_DiaryJob = React.lazy(() => import("../modules/Rsdt_DiaryJob"));
const Rsdt_DiarySocialInsurance = React.lazy(() => import("../modules/Rsdt_DiarySocialInsurance"));
const Rsdt_DiaryHealthInsurance = React.lazy(() => import("../modules/Rsdt_DiaryHealthInsurance"));
const Rsdt_DeadPeople = React.lazy(() => import("../modules/Rsdt_DeadPeople"));
const Rsdt_TK_StatusHousehold = React.lazy(() => import("../modules/Rsdt_TK_StatusHousehold"));
const Rsdt_TK_Evils = React.lazy(() => import("../modules/Rsdt_TK_Evils"));
const Rsdt_TK_Military = React.lazy(() => import("../modules/Rsdt_TK_Military"));
const Rsdt_TK_Education = React.lazy(() => import("../modules/Rsdt_TK_Education"));
const Rsdt_TK_ByAgePeople = React.lazy(() => import("../modules/Rsdt_TK_ByAgePeople"));
const Rsdt_TK_CultureHousehold = React.lazy(() => import("../modules/Rsdt_TK_CultureHousehold"));
const Rsdt_TK_UnionMembers = React.lazy(() => import("../modules/Rsdt_TK_UnionMembers"));
const Rsdt_TK_UnionWomen = React.lazy(() => import("../modules/Rsdt_TK_UnionWomen"));
const Rsdt_TK_LifeDeathPeople = React.lazy(() => import("../modules/Rsdt_TK_LifeDeathPeople"));
const Rsdt_ReportHousehold = React.lazy(() => import("../modules/Rsdt_ReportHousehold"));
const Rsdt_ReportPeople = React.lazy(() => import("../modules/Rsdt_ReportPeople"));
const Rsdt_ReportStatusHousehold = React.lazy(() => import("../modules/Rsdt_ReportStatusHousehold"));
const Rsdt_ReportMeritHousehold = React.lazy(() => import("../modules/Rsdt_ReportMeritHousehold"));
const Rsdt_ReportPolicyHousehold = React.lazy(() => import("../modules/Rsdt_ReportPolicyHousehold"));
const Rsdt_ReportCultureHousehold = React.lazy(() => import("../modules/Rsdt_ReportCultureHousehold"));
const Rsdt_ReportBusinessHousehold = React.lazy(() => import("../modules/Rsdt_ReportBusinessHousehold"));
const Rsdt_ReportVaccination = React.lazy(() => import("../modules/Rsdt_ReportVaccination"));
const Rsdt_ReportSubsidize = React.lazy(() => import("../modules/Rsdt_ReportSubsidize"));
const Rsdt_ReportJob = React.lazy(() => import("../modules/Rsdt_ReportJob"));
const Rsdt_ReportEvils = React.lazy(() => import("../modules/Rsdt_ReportEvils"));
const Rsdt_ReportPeopleAge = React.lazy(() => import("../modules/Rsdt_ReportPeopleAge"));
const Rsdt_ReportMilitary = React.lazy(() => import("../modules/Rsdt_ReportMilitary"));
const Rsdt_ReportEducation = React.lazy(() => import("../modules/Rsdt_ReportEducation"));
const Rsdt_ReportUnionMembers = React.lazy(() => import("../modules/Rsdt_ReportUnionMembers"));
const Rsdt_ReportUnionWomen = React.lazy(() => import("../modules/Rsdt_ReportUnionWomen"));
/////////////////////NONG NGHIEP (AGRI)//////////////////////////
const agri_AlikeTree = React.lazy(() => import("../modules/agri_AlikeTree"));
const agri_TypeTree = React.lazy(() => import("../modules/agri_TypeTree"));
const agri_FormCirculate = React.lazy(() => import("../modules/agri_FormCirculate"));
const agri_LimitCirculate = React.lazy(() => import("../modules/agri_LimitCirculate"));
const agri_AlikeCirculate = React.lazy(() => import("../modules/agri_AlikeCirculate"));
const agri_ReportAlikeCirculate = React.lazy(() => import("../modules/agri_ReportAlikeCirculate"));
const publicRouters = [
    /// Fragment = null layout
    { path: "/login", component: Login, layout: Fragment },
];
const privateRouters = [
    ///default layout = LayoutAdmin
    { path: "/", component: Dashboard },
    { path: "/register", component: Register },
    { path: "/logout", component: Logout },
    { path: "/role", component: Role },
    { path: "/staff", component: Staff },
    { path: "/diary_personal", component: DiaryPersonal },
    { path: "/user", component: User },
    { path: "/report_account", component: ReportAccount },
    { path: "/menu", component: Menu },
    { path: "/module_permission", component: Module_Permission },
    { path: "/function_permission", component: Function_Permission },
    { path: "/product", component: Product },
    { path: "/product_cate", component: ProductCate },
    { path: "/organize", component: Organize },
    { path: "/organize_info", component: OrganizeInfo },
    { path: "/calendar_work", component: CalendarWork },
    { path: "/nationality_cate", component: CateNationality },
    { path: "/religion_cate", component: CateReligion },
    { path: "/organize_cate", component: CateOrganize },
    { path: "/ethnic_cate", component: CateEthnic },
    { path: "/position_union_cate", component: CatePositionUnion },
    { path: "/position_party_cate", component: CatePositionParty },
    { path: "/position_trade_union_cate", component: CatePositionTradeUnion },
    { path: "/language_certificate_cate", component: CateLanguageCertificate },
    { path: "/informatics_certificate_cate", component: CateInformaticsCertificate },
    { path: "/forms_training_cate", component: CateFormsTraining },
    ///////////Ho khau & Nhan khau///////////////////
    { path: "/rsdt_cate_relationship", component: Rsdt_CateRelationship },
    { path: "/rsdt_household", component: Rsdt_Household },
    { path: "/rsdt_people", component: Rsdt_People },
    { path: "/rsdt_diary_status_household", component: Rsdt_DiaryStatusHousehold },
    { path: "/rsdt_diary_merit_household", component: Rsdt_DiaryMeritHousehold },
    { path: "/rsdt_diary_policy_household", component: Rsdt_DiaryPolicyHousehold },
    { path: "/rsdt_diary_business_household", component: Rsdt_DiaryBusinessHousehold },
    { path: "/rsdt_diary_register_culture_household", component: Rsdt_DiaryRegisterCultureHousehold },
    { path: "/rsdt_diary_culture_household", component: Rsdt_DiaryCultureHousehold },
    { path: "/rsdt_diary_personal", component: Rsdt_DiaryPersonal },
    { path: "/rsdt_diary_vaccination", component: Rsdt_DiaryVaccination },
    { path: "/rsdt_diary_evils", component: Rsdt_DiaryEvils },
    { path: "/rsdt_diary_military", component: Rsdt_DiaryMilitary },
    { path: "/rsdt_diary_union_members", component: Rsdt_DiaryUnionMembers },
    { path: "/rsdt_diary_union_women", component: Rsdt_DiaryUnionWomen },
    { path: "/rsdt_diary_education", component: Rsdt_DiaryEducation },
    { path: "/rsdt_diary_subsidize", component: Rsdt_DiarySubsidize },
    { path: "/rsdt_diary_job", component: Rsdt_DiaryJob },
    { path: "/rsdt_diary_social_insurance", component: Rsdt_DiarySocialInsurance },
    { path: "/rsdt_diary_health_insurance", component: Rsdt_DiaryHealthInsurance },
    { path: "/rsdt_dead_people", component: Rsdt_DeadPeople },
    { path: "/rsdt_tk_status_household", component: Rsdt_TK_StatusHousehold },
    { path: "/rsdt_tk_evils", component: Rsdt_TK_Evils },
    { path: "/rsdt_tk_military", component: Rsdt_TK_Military },
    { path: "/rsdt_tk_education", component: Rsdt_TK_Education },
    { path: "/rsdt_tk_by_age_people", component: Rsdt_TK_ByAgePeople },
    { path: "/rsdt_tk_culture_household", component: Rsdt_TK_CultureHousehold },
    { path: "/rsdt_tk_union_members", component: Rsdt_TK_UnionMembers },
    { path: "/rsdt_tk_union_women", component: Rsdt_TK_UnionWomen },
    { path: "/rsdt_tk_life_death_people", component: Rsdt_TK_LifeDeathPeople },
    { path: "/rsdt_report_household", component: Rsdt_ReportHousehold },
    { path: "/rsdt_report_people", component: Rsdt_ReportPeople },
    { path: "/rsdt_report_status_household", component: Rsdt_ReportStatusHousehold },
    { path: "/rsdt_report_merit_household", component: Rsdt_ReportMeritHousehold },
    { path: "/rsdt_report_policy_household", component: Rsdt_ReportPolicyHousehold },
    { path: "/rsdt_report_culture_household", component: Rsdt_ReportCultureHousehold },
    { path: "/rsdt_report_business_household", component: Rsdt_ReportBusinessHousehold },
    { path: "/rsdt_report_vaccination", component: Rsdt_ReportVaccination },
    { path: "/rsdt_report_subsidize", component: Rsdt_ReportSubsidize },
    { path: "/rsdt_report_job", component: Rsdt_ReportJob },
    { path: "/rsdt_report_evils", component: Rsdt_ReportEvils },
    { path: "/rsdt_report_people_age", component: Rsdt_ReportPeopleAge },
    { path: "/rsdt_report_military", component: Rsdt_ReportMilitary },
    { path: "/rsdt_report_education", component: Rsdt_ReportEducation },
    { path: "/rsdt_report_union_members", component: Rsdt_ReportUnionMembers },
    { path: "/rsdt_report_union_women", component: Rsdt_ReportUnionWomen },
    /////////////////NONG NGHIEP (AGRI)////////////////////////////
    { path: "/agri_alike_tree", component: agri_AlikeTree },
    { path: "/agri_type_tree", component: agri_TypeTree },
    { path: "/agri_form_circulate", component: agri_FormCirculate },
    { path: "/agri_limit_circulate", component: agri_LimitCirculate },
    { path: "/agri_alike_circulate", component: agri_AlikeCirculate },
    { path: "/agri_report_alike_circulate", component: agri_ReportAlikeCirculate },
];

export { publicRouters, privateRouters };
